import React from "react"
import Header from "../../components/Header"
import Ecomm3Results from "../../components/homepage/Ecomm3Results"
import { graphql } from "gatsby"
const locale = "es"
const results = ({data})=> {
  return(
    <React.Fragment>
      <Header locale={locale}/>
      <Ecomm3Results data={data.allContentfulEComm3Results}/>
    </React.Fragment>
  )
}
export default results


export const query = graphql`
  query ResultadosQuery{
    allContentfulEComm3Results(filter: {node_locale: {eq: "es"}}) {
      nodes {
        bigText
        subtitle
        title
        node_locale
      }
    }
  }
`